import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from "@mui/material";

import React from "react";
import PlotAPI from "../../../src/plotAPI";
import Helper from "../../Helper";

interface Props {
    showEulaModal: boolean;
    setShowEulaModal(value: boolean): void;
}

const EulaAcceptModal: React.FC<Props> = ({ showEulaModal, setShowEulaModal }) => {
    const setEulaAccepted = async () => {
        const url = "/users/me/accept_eula";
        const options = {
            method: "post",
        };

        return Helper.plotFetch(url, options, true)
            .then((data) => {
                if (data.ok) {
                    PlotAPI.getCurrentUserInfo();
                }
                throw new Error("Network error.");
            })
            .catch(() => {
                return null;
            });
    };

    return (
        <>
            <Dialog
                open={showEulaModal}
                onClick={(e) => e.stopPropagation()}
                onClose={setEulaAccepted}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={"paper"}
                TransitionComponent={Slide}
            >
                <DialogTitle id="alert-dialog-title">EULA Acceptance</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="subtitle2" display="block" gutterBottom>
                            In order to proceed with using PLOT, please review and accept our{" "}
                            <a
                                className="text-plot-orange font-semibold"
                                href="https://www.getplot.com/resources/end-user-license-agreement"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                End User License Agreement
                            </a>{" "}
                            .
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        className="plot-button primary-button button-sm"
                        onClick={() => {
                            setEulaAccepted().then(() => {
                                setShowEulaModal(false);
                            });
                        }}
                    >
                        Accept and Close
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EulaAcceptModal;
